import css from './Brand.module.scss'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image';
import { useRouter } from 'next/router'
import Link from 'next/link'
import { observerService } from '@/utils/observer';
import { memo } from 'react';

const Brand = ({ locale, customClass}) => {
  const { t } = useTranslation('translations-auction')
	const router = useRouter()
  let imgSrc = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/manuals/logos/auction-wasalt-logo${locale === 'ar' ? '-ar' : ''}.svg`
  if(router?.route === "/user/properties" ){
    imgSrc = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/wasalt-logo${locale === 'ar' ? '-ar' : ''}.svg`
  }


const handleClick = () => {
  observerService.handleEvent({type: 'home'});
}
// we have create a parent component for the wrapper  
const RenderLogoWithLinkOrNot = ({children}) => {
  let content = "";
  const noRedirect = ["/user/properties"];
  if(noRedirect.includes(router?.route)){
    content = (
    <Link prefetch={false} href={`${process.env.NEXT_PUBLIC_BASE_URL}${locale === 'ar' ? '' : '/en'}`} passHref locale={locale} onClick={handleClick} title={t('common.WASALT')}>
        {children}
      </Link>
    )
  } else {
    content = (
      <Link prefetch={false} href={'/'} passHref locale={locale} onClick={handleClick} title={t('common.WASALT')}>
        {children}
      </Link>
    )
  }
  return content
}

  return (
    <div className={`${css.brand} ${customClass}`}>
      <RenderLogoWithLinkOrNot>
        <div className={css.logoDiv} >
          <Image
            priority={true}
            width={160}
            height={62}
            src={imgSrc}
            alt={locale === 'ar' ? 'شعار وصلت' : 'Wasalt Logo'}
            className={css.logo}
          />
        </div>
      </RenderLogoWithLinkOrNot>
    </div>
  )
}
export default memo(Brand)
