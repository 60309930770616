import React from 'react'
import { useRouter } from 'next/router'
import styles from './SubFooter.module.scss'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import SocialLinks from '../SocialLinks'
import IconoGraphy from '../../IconoGraphy'
import { pushDataLayerEventHandler } from '@/utils/utils'

const SubFooter = (props) => {
  const {page} = props
  const router = useRouter()
  const { t, lang } = useTranslation('translations-auction')

  const privacyLinks = [
    // { id: 1, link: t('common.OUR_STORY'), path: '/about' },
    { id: 2, link: t('common.Corporate'), path: `${process.env.NEXT_PUBLIC_WASALTBASE_URL}${lang == 'ar' ? '':'/en'}/corporate`, name: 'corporate_clicked' },
    { id: 2, link: t('common.PRIVACY_POLICY'), path: '/privacy-policy' },
    { id: 3, link: t('common.TERMS_OF_USE'), path: '/terms-of-use' },
    { id: 4, link: t('common.TERMS_OF_ADVERTISING'), path: '/terms-of-advertising' },
  ]

  const privacyLinksClickHandler = (linkName) => {
      pushDataLayerEventHandler(window, lang, linkName, page)
  }

  const privacyLinksToRender = privacyLinks.map((link) => (
    <Link key={link.id} href={link.path} prefetch={false} className={styles.privacyLink} onClick={() => privacyLinksClickHandler(link.name)}>
      {link.link}
    </Link>
  ))

  const refPath = () => {
    if (lang === 'ar') {
      return `/en/${router.asPath}`
    }
    return router.asPath
  }

  return (
    <div className={styles.lowerFooter}>
      <div className={styles.socialIcons}>
        <SocialLinks lang={lang} page={page}/>
      </div>
      <div className={styles.legal}>
        {privacyLinksToRender}
        <div className={styles.language}>
          <IconoGraphy iconClass={'icon-globe'} iconColor={'color_gray'} fontSize={'f_16'}></IconoGraphy>
          <a href={refPath()} className={styles.language_link} dir={lang === 'ar' ? 'ltr' : 'rtl'}>
            {t('common.LANGUAGE')}
          </a>
        </div>
      </div>
    </div>
  )
}

export default SubFooter
