import IconoGraphy from '@/components/Common/IconoGraphy'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import cdnLoader from '@/utils/cdnLoader'

import LottieLiveTag from '@/components/Common/LotteLiveTag'
import FollowButton from '@/components/Common/Follow'
import { getImageLinks } from '@/utils/auctionUtils'
import ImageLazy from '@/components/Common/ImageLazy'
import { useRef } from 'react'
const CardHeader = ({
  styles,
  status,
  auctionTypeText,
  tags,
  imageLink,
  hasFollowed = false,
  auctionData,
  title,
  cardtags,
  sellerSlugForRegisteredAuction,
  isPageTypeProfile,
  isRegisteredCard,
  auctionType,
  refetchFollowedAuctions,
  isDesktopSSR = null,
  cardIndex,
  isEditModeEnable,
  isGroupPvtAuction = false
}) => {
  const { t } = useTranslation('translations-auction')
  const imgRef = useRef(null)
  const getImageLink = () => {
    if (isEditModeEnable?.isEditing || isEditModeEnable?.status === 'reject') {
      return `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/image_placeholder.webp`
    }
    return getImageLinks(imageLink, auctionData, !isPageTypeProfile && sellerSlugForRegisteredAuction, !isPageTypeProfile && isGroupPvtAuction)
  }  
  const isLive = status === 'live'
  const pageType = isPageTypeProfile === true ? 'Profile' : 'Auction_SRP'
  const showImageCheck = isDesktopSSR ? cardIndex < 6 : cardIndex === 0

  return (
    <div className={styles.figure}>
      {showImageCheck ? (
        <Image loader={cdnLoader} src={getImageLink()} layout='fill' className={styles.imagefit} alt={title} />
      ) : (
        <ImageLazy ref={imgRef} alt={title} imgURL={getImageLink()} customClass={styles.imagefit} />
      )}
      {((!['au-infath', 'au-wasalt-group'].includes(auctionData?.seller)) || isRegisteredCard) && (
        <FollowButton
          hasFollowed={hasFollowed}
          auctionTypeStatus={auctionData?.status}
          auctionId={auctionData?.auctionId}
          btnType='bell'
          followData={auctionData}
          pageType={pageType}
          auctionType={auctionType}
          FollowId={auctionData?.id}
          refetchFollowedAuctions={refetchFollowedAuctions}
          groupId={auctionData?.groupId}
        />
      )}
      <div className={styles.tag_wrapper}>
        <div className={styles.tag} data-type={status}>
          <div className={`${styles.auction_type} ${isLive && styles.auction_type_Live}`}>
            {isLive ? (
              <LottieLiveTag />
            ) : (
              <>
                <span className={styles.auctionOne}>
                  <IconoGraphy iconClass={'icon-auction-outline'} iconColor={'color_white'} fontSize={'f_16'} />
                </span>
                <span className={styles.timerOne}>
                  <IconoGraphy iconClass={'icon-timer-flash'} iconColor={'color_white'} fontSize={'f_16'} />
                </span>
                <span className={styles.cancelOne}>
                  <IconoGraphy iconClass={'icon-block'} iconColor={'color_white'} fontSize={'f_12'} />
                </span>
              </>
            )}
            <div className={styles.statustext}>{auctionTypeText}</div>
          </div>
        </div>
        {auctionData?.subStatusType === 'sub-auction-closed-sold' && isPageTypeProfile && (
          <div className={styles.tag} data-type={auctionData?.subStatusType}>
          <div className={`${styles.auction_type}`}>
            <span className={styles.auctionOne}>
              <IconoGraphy iconClass={'icon-check-circle'} iconColor={'color_revamp_green'} fontSize={'f_16'}></IconoGraphy>
            </span>
            <div className={styles.statustext}>{t('auctionGroupDetail.sold')?.toLowerCase()}</div>
          </div>
          </div>
        )}
        {isPageTypeProfile && (
          <div className={styles.infathTag}>
            <div className={styles.inner}>{auctionType === 'infath-online' ? t('auctionCommon.INFATH') : t(`auctionCommon.Private`)}</div>
          </div>
        )}
        {(['online', 'private-group-online'].includes(cardtags)) && (
          <div className={styles.OnlineTag}>
            <span>{t('user.ONLINE').toLowerCase()}</span>
          </div>
        )}
      </div>
      <div className={styles.overlay}>
        <div></div>
        <div className={styles.auctionTitleTag}>
          {tags.map((tag) => {
            if (tag) return <span>{tag}</span>
          })}
        </div>
      </div>
    </div>
  )
}
export default CardHeader
